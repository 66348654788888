//------------------------ FONT SIZES -----------------------------
.cfs-07 {
  font-size: 0.7rem;
}
.cfs-08 {
  font-size: 0.8rem;
}
.cfs-09 {
  font-size: 0.9rem;
}
.cfs-1 {
  font-size: 1rem;
}
.cfs-11 {
  font-size: 1.1rem;
}
.cfs-12 {
  font-size: 1.2rem;
}
.cfs-13 {
  font-size: 1.3rem;
}
.cfs-14 {
  font-size: 1.4rem;
}
.cfs-15 {
  font-size: 1.5rem;
}
