$colorGradiantSteps: 10;
$colorBaseGray: #000000;

@for $i from 0 through 10 {
  $percentage: $i * 10%;
  .s56-bgc-gray-#{$i * 10} {
    background-color: lighten($colorBaseGray, $percentage);
  }

  .s56-font-color-gray-#{$i * 10} {
    color: lighten($colorBaseGray, $percentage);
  }
}


$icon-font-gray: #9e9e9e;
$icon-font-transparent-white: #d6d6d6;
$button-blue: #2C5A70;
$hover-darker-gray: #e4e4e4;
$table-header-bg-color: #e3e3e3;
$table-hover-line-bg-color: #e7e7e7;

/* Color Theme Swatches in Hex */
$Petrol-1-hex: #27BBC7;
$Petrol-2-hex: #1A7F87;
$Petrol-3-hex: #0e636a;
$Petrol-4-hex: #0E4448;
$Petrol-5-hex: #0A3033;

.ccb-petrol-1 {
  background-color: $Petrol-1-hex;
}
.ccb-petrol-2 {
  background-color: $Petrol-2-hex;
}
.ccb-petrol-3 {
  background-color: $Petrol-3-hex;
}
.ccb-petrol-4 {
  background-color: $Petrol-4-hex;
}
.ccb-petrol-5 {
  background-color: $Petrol-5-hex;
}

$cc-gray-base: #9a9a9a;
$cc-gray-p10: #b6b6b6;
$cc-gray-p20: #c7c7c7;
$cc-gray-p30: #cecece;
$cc-gray-p40: #e2e2e2;
$cc-gray-p50: #f3f3f3;
$cc-gray-p60: #f3f3f3;
$cc-gray-m10: darken(adjust-hue($cc-gray-base, -5), 10%);
$cc-gray-m20: darken(adjust-hue($cc-gray-base, -10), 20%);
$cc-gray-m30: darken(adjust-hue($cc-gray-base, -20), 30%);
.ccb-gray-base {
  background: $cc-gray-base;
}
.ccb-gray-p10 {
  background: $cc-gray-p10;
}
.ccb-gray-p20 {
  background: $cc-gray-p20;
}
.ccb-gray-p30 {
  background: $cc-gray-p30;
}
.ccb-gray-p40 {
  background: $cc-gray-p40;
}
.ccb-gray-p50 {
  background: $cc-gray-p50;
}
.ccb-gray-p60 {
  background: $cc-gray-p60;
}
.ccb-gray-m10 {
  background: $cc-gray-m10;
}
.ccb-gray-m20 {
  background: $cc-gray-m20;
}
.ccb-gray-m30 {
  background: $cc-gray-m30;
}

.cc-gray-base {
  color: $cc-gray-base;
}
.cc-gray-p10 {
  color: $cc-gray-p10;
}
.cc-gray-p20 {
  color: $cc-gray-p20;
}
.cc-gray-p30 {
  color: $cc-gray-p30;
}
.cc-gray-p40 {
  color: $cc-gray-p40;
}
.cc-gray-p50 {
  color: $cc-gray-p50;
}
.cc-gray-p60 {
  color: $cc-gray-p60;
}
.cc-gray-m10 {
  color: $cc-gray-m10;
}
.cc-gray-m20 {
  color: $cc-gray-m20;
}
.cc-gray-m30 {
  color: $cc-gray-m30;
}

$cc-red-base:#970000;
$cc-red-p10: lighten(adjust-hue($cc-red-base, 5), 10%);
$cc-red-p20: lighten(adjust-hue($cc-red-base, 10), 20%);
$cc-red-m10: darken(adjust-hue($cc-red-base, -5), 10%);
$cc-red-m20: darken(adjust-hue($cc-red-base, -10), 20%);
.ccb-red-base {
  background: $cc-red-base;
}
.ccb-red-p10 {
  background: #6c0000;
}
.ccb-red-p20 {
  background: #9a1010;
}
.ccb-red-m10 {
  background: #c43232;
}
.ccb-red-m20 {
  background: #d26363;
}

.cc-red-base {
  color: $cc-red-base;
}
.cc-red-p10 {
  color: #6c0000;
}
.cc-red-p20 {
  color: #9a1010;
}
.cc-red-m10 {
  color: #c43232;
}
.cc-red-m20 {
  color: #d26363;
}

//################## BACKGROUND COLORS ###################
.s56-bgc-lightgray {
  background-color: lightgray;
}

//################## FONT COLORS ###################
.s56-fc-gray-01 {
  color: #9e9e9e;
}

.s56-fc-whitesmoke {
  color: whitesmoke;
}
