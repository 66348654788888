@import "quill/dist/quill.bubble.css";

@import "sass/colors";
@import "sass/fonts";
@import "sass/styles";

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 15px;
  color: #424649;
  letter-spacing: 1.2px;
  background-color: rgba(50, 54, 68, 0.02);
}

h1 {
  font-family: 'Roboto Black', sans-serif;
}

input, textarea, select {
  border: 1px solid #e8e8e8;
  padding: 5px;
  font-size: 15px;
  outline: none;
}

button {
  border: 1px solid $button-blue;
  background-color: $button-blue;
  padding: 5px 15px;
  outline: none;
  color: white;
  text-transform: uppercase;
}

a {
  text-decoration: none !important;
  color: $Petrol-5-hex !important;

  &:hover {
    color: $Petrol-2-hex !important;
  }
}

.min-height-100 {
  min-height: 100vh;
}

.button-line {
  padding: 15px 0 25px 0;

  button {
    background-color: $button-blue;
    padding: 10px 25px;
    color: white;
    text-transform: uppercase;
  }
}

.shaddow {
  -webkit-box-shadow: 10px 10px 18px -4px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 10px 10px 18px -4px rgba(0, 0, 0, 0.46);
  box-shadow: 10px 10px 18px -4px rgba(0, 0, 0, 0.46);
}

.button-line {
  width: auto;
  text-align: right;
}

.form-line {
  display: flex;
  margin: 0 0 15px 0;

  .label-box {
    width: 190px;
    padding-top: 6px;
    margin-right: 15px;

    &:after {
      content: ':';
    }
  }

  .form-box {
    width: 100%;
    padding: 0 15px 0 0;

    textarea {
      width: 100%;
      height: 250px;
    }
  }
}

.button-dark {
  background-color: #24282b;
  color: white;
}

.navigation-main-box {
  text-align: right;
  padding: 15px 25px 0 0;

  .inner-box {

    .action-icon-box {
      font-size: 1.3rem;
      color: #757575;
      margin-right: 8px;
      cursor: pointer;

      .action-main-box {
        position: absolute;
        background-color: white;
        border: 1px solid #E0E0E0;
        right: 25px;
        margin-top: 10px;
        font-size: 1rem;

        .action-item {
          display: flex;
          padding: 5px 15px;

          &:hover {
            font-weight: bolder;

            .action-item-icon {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.button-margin-right {
  margin-right: 10px;
}

.spacer-right-10 {
  margin-right: 10px;
}

.spacer-buttom-10 {
  margin-bottom: 10px;
}

/** Padding */
.padding-15 {
  padding: 15px;
}

.dialog-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(36, 40, 43, 0.4);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.function-button {
  padding: 10px;
  text-align: center;
  color: #2c5a70;
  border: 1px solid #2c5a70;
  cursor: pointer;
}

.event-content-item-dialog {
  display: block;

  .mat-dialog-container {

    .event-content-item-list {
      display: flex;
      width: 70vw;

      .list-column {
        width: 50%;

        .list-cont {
          max-height: 400px;
          overflow: auto;

          .event-content-item {
            padding: 5px;
            background-color: #9a9999;
            cursor: pointer;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.padding-15 {
  padding: 15px;
}

.padding-25 {
  padding: 25px;
}

.function-button-area {
  padding: 25px 0px;

  button {
    background-color: $button-blue;
    border: 0px;
    color: whitesmoke;
    padding: 10px;
    letter-spacing: 1.5px;
  }
}

.left-margin-15 {
  margin-left: 15px;
}

.dialog-button {
  background-color: whitesmoke;
  border: 1px solid whitesmoke;
}

.file-dropzone-area {
  border: 1px dotted #2c5a70;

  &:hover {
    background-color: #ededed;

    .icon {
      color: #49758a;
    }

    .button {
      background-color: #49758a;
    }
  }

  .icon {
    font-size: 35px;
    color: #2c5a70;
    opacity: 0.6;
    margin-right: 25px;
  }
}

.table-icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 18px;
}
