@for $i from 1 through 20 {
  $percentage: $i * 5;
  .w-#{$percentage} {
    width: #{$percentage}#{"%"} !important;
  }
}

.button-on-field {
  background-color: transparent;
  padding: 4px 8px;
  color: #505050;
  font-weight: 100;
  border: 1px solid silver;
  border-radius: 4px;
}

.header-logo {
  position: fixed;
  width: 100%;
  height: 100px;
  z-index: 100;
  display: flex;
  justify-content: center;
  background: linear-gradient(to bottom, #a90329 0%,#8f0222 44%,#6d0019 100%);
  text-align: center;

  img {
    width: 200px;
  }
}

.s56-header {
  background-color: white;
}

.table-header {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
  margin: 0 15px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: bold;
  color: #7b809a;
}

.table-row {
  display: flex;
  flex-wrap: nowrap;
  padding: 5px 15px;
  margin: 0 8px;
  font-size: 0.9rem;
  color: #7b809a;
  cursor: pointer;

  fa-icon {
    color:  #7b809a;
  }
}

.font-color-blue {
  color: #263c79;
}

@for $i from 1 through 100 {
  .table-col-width-#{$i} {
    width: calc(100% / 100 * #{$i});
  }
}

.s56-table {
  width: 100%;

  .s56-table-section {
    background-color: white;
    padding: 8px;
    margin-bottom: 25px;
  }

  .s56-table-header {
    display: flex;
    border-bottom: 0.5px solid silver;
    margin-bottom: 20px;

    .s56-column {
      color: #737373;
      font-weight: 300;
      padding: 4px;
    }
  }

  .s56-table-body {

    .s56-table-content-center {
      display: flex;
      justify-content: center;
    }

    .s56-table-line {
      display: flex;
      font-weight: 400;
      background-color: #ffffff;


      &:hover {
        background-color: #f6f6f6;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .s56-column {
        padding: 4px;
        color: #737373;
        font-size: 0.9rem;
        position: relative;

        .s56-table-menu {
          position: absolute;
          background-color: white;
          right: 0;
          box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.06) 0 2px 4px -1px;
          border-radius: 5px;
          z-index: 500;
          width: 250px;

          .s56-table-menu-item  {
            padding: 4px 16px;
            display: flex;
            cursor: pointer;
            text-align: left;

            fa-icon {
              margin-right: 8px;
            }

            &:hover {
              color: black;
            }
          }
        }
      }
    }
  }
}

dialog {
  border: none !important;
}

.dialog-main {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.dialog-center {
  background-color: white;
  border-radius: 5px;
  width: 40%;
}

.dialog-title {
  background-color: #eeeeee;
  color: #565656;
  padding: 8px;
  text-align: center;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
}

.dialog-content {
  padding: 24px;
  box-sizing: border-box;
}

.dialog-line {
  margin-bottom: 16px;
  box-sizing: border-box;

  &:last-of-type {
    margin-bottom: 0;
  }

  .dialog-label {

  }

  input, textarea {
    width: 100%;
    border-radius: 5px;
  }

}

.dialog-buttons {
  display: flex;
  justify-content: end;
  padding: 0 24px 24px 24px;

  button {
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.dialog-auto-complete-main {
  position: absolute;
  border: 1px solid #ececec;
  left: 0;
  right: 0;
  top: 33px;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.06) 0 2px 4px -1px;
  padding-top: 5px;

  .dialog-auto-complete-item {
    margin-bottom: 5px;
    padding: 0 8px;
    cursor: pointer;

    &:hover {
      background-color: #e8e8e8;
    }
  }
}

.list-line {
  display: flex;
  gap: 8px;
  background-color: #ffffff;
  margin-bottom: 7px;
  border-radius: 5px;
  padding: 5px;
  align-items: center;
  cursor: pointer;

  &.white-background {
    background-color: #f6f6f6;
  }

  &:hover {
    background-color: #f6f6f6;
    box-shadow: rgba(111, 114, 117, 0.2) 0 8px 24px;
    transform: translate(-1px, -1px);
  }

  .user-searchresult-avatar {
    width: 45px;
  }
}

.section-main-title {
  font-weight: 600;
  font-size: 34px;
  line-height: 1.2;
  color: #023535;
  padding-bottom: 16px;
  border-bottom: 1px solid #0E4448;
}

.card-item {
  padding: 24px 16px;
  box-shadow: 0 0 20px #33407a14;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: relative;
}

.card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #525252;
  padding-bottom: 16px;
}

.card-add-icon {
  position: absolute;
  right: 25px;
  top: 25px;
  color: #bebebe;
  font-size: 16px;
}

.card-key-value {
  display: flex;
  border-bottom: 1px solid #ececec;
  color: #606770;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.2;
  padding: 0 8px 4px;
  margin-bottom: 14px;

  div:first-child {
    width: 50%;
  }

  div:last-child {
    width: 50%;
    text-align: right;
  }
}

.subnavigation {
  display: flex;
  background-color: white;
  width: 100%;
  justify-content: center;
  margin-top: -25px;
  height: 50px;
  align-items: center;
  padding-top: 10px;

  div {
    margin-right: 16px;
    border-bottom: 1.5px solid transparent;

    &:last-of-type {
      margin-right: 0;
    }

    &.active {
      border-bottom: 1.5px solid darkred;
    }
  }
}

.list-section-title {
  font-weight: 500;
  margin: 16px 0;
}

.list-item {
  background-color: white;
  padding: 8px;
  border: 1px solid #dcdcdc;
  margin-bottom: 8px;
  border-radius: 4px;
  color: #606060;
  font-size: 13px;
  display: flex;
  gap: 8px;

  fa-icon {
    color: #adadad;
  }

  > div {
    border-right: 0.5px solid silver;
    padding-right: 8px;

    &:nth-of-type(3), &:nth-of-type(2) {
      color: #adadad;
    }

    &:last-of-type {
      border-right: 0;
    }
  }
}
